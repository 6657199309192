<div class="box-body">
    <ul class="nav nav-tabs justify-content-end" role="tablist">
        <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#DonHang" role="tab"><span
                    class="hidden-sm-up"><i class="ion-home"></i></span> <span class="hidden-xs-down">Order
                    [{{DonHangService.FormData.ID}}]</span></a>
        </li>
        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#DonHangChiTiet" role="tab"><span
                    class="hidden-sm-up"><i class="ion-person"></i></span> <span
                    class="hidden-xs-down">Service</span></a> </li>
        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#DonHangThanhToan" role="tab"><span
                    class="hidden-sm-up"><i class="ion-email"></i></span> <span
                    class="hidden-xs-down">Payment</span></a> </li>
        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#DonHangTapTinDinhKem" role="tab"><span
                    class="hidden-sm-up"><i class="ion-email"></i></span> <span
                    class="hidden-xs-down">Document</span></a> </li>
        <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#ThanhVienBlock" role="tab"><span
                    class="hidden-sm-up"><i class="ion-email"></i></span> <span class="hidden-xs-down">Block</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-link bg-danger" data-bs-toggle="tab" role="tab" style="cursor: pointer; color: #ffffff;"
                (click)="Close()"><i class="fa fa-window-close"></i></a>
        </li>
    </ul>
    <div class="tab-content tabcontent-border">
        <div class="tab-pane active" id="DonHang" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <a style="width: 100%;" title="Save changes" (click)="DonHangSave()" class="btn btn-warning"><i
                            class="fa fa-save"></i> Save changes</a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="checkbox checkbox-success">
                        <input type="checkbox" id="Active" [(ngModel)]="DonHangService.FormData.Active" />
                        <label for="Active"> Active </label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="checkbox checkbox-success">
                        <input type="checkbox" id="IsThanhToan" [(ngModel)]="DonHangService.FormData.IsThanhToan"
                            disabled />
                        <label for="IsThanhToan"> Payment </label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="checkbox checkbox-success">
                        <input type="checkbox" id="IsHoanThanh" [(ngModel)]="DonHangService.FormData.IsHoanThanh"
                            disabled />
                        <label for="IsHoanThanh"> Completed </label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Account [{{DonHangService.FormData.NguoiMuaID}}]</label>
                        <mat-select class="form-control" name="DonHangService.FormData.NguoiMuaID"
                            [(ngModel)]="DonHangService.FormData.NguoiMuaID" (selectionChange)="NguoiMuaChange()"
                            disabled>
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="ThanhVienFilter($event.target.value)">
                            <mat-option *ngFor="let item of ThanhVienService.ListFilter" [value]="item.ID">
                                {{item.Name}} - {{item.DienThoai}} - {{item.DiaChiVi}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Buyer</label>
                        <input name="NguoiMuaName" [(ngModel)]="DonHangService.FormData.NguoiMuaName"
                            placeholder="Buyer" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sender</label>
                        <input name="NguoiMuaDiaChiVi" [(ngModel)]="DonHangService.FormData.NguoiMuaDiaChiVi"
                            placeholder="Sender" type="text" class="form-control" disabled>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Date created</label>
                        <input [ngModel]="DonHangService.FormData.NgayGhiNhan | date:'yyyy-MM-dd'"
                            style="text-align: right;" (ngModelChange)="DateNgayGhiNhan($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Name</label>
                        <input name="Name" [(ngModel)]="DonHangService.FormData.Name" placeholder="Name" type="text"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Note</label>
                        <input name="Note" [(ngModel)]="DonHangService.FormData.Note" placeholder="Note" type="text"
                            class="form-control">
                    </div>

                </div>
                <div class="col-lg-6 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Transation</label>
                        <input name="SoLuongTransation" [(ngModel)]="DonHangService.FormData.SoLuongTransation"
                            placeholder="Transation" type="number" disabled class="form-control"
                            style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Gas</label>
                        <input name="SoLuongGas" [(ngModel)]="DonHangService.FormData.SoLuongGas" placeholder="Gas"
                            type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Month</label>
                        <input name="SoLuongThang" [(ngModel)]="DonHangService.FormData.SoLuongThang"
                            placeholder="Month" type="number" disabled class="form-control" style="text-align: right;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Total</label>
                        <input name="ThanhTienSauCung" [(ngModel)]="DonHangService.FormData.ThanhTienSauCung"
                            placeholder="Total" type="number" disabled class="form-control"
                            style="text-align: right; font-weight: bold;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Paid</label>
                        <input name="ThanhToan" [(ngModel)]="DonHangService.FormData.ThanhToan" placeholder="Paid"
                            type="number" disabled class="form-control"
                            style="text-align: right; font-weight: bold; color: green;">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Debt</label>
                        <input name="ConLai" [(ngModel)]="DonHangService.FormData.ConLai" placeholder="Debt"
                            type="number" disabled class="form-control"
                            style="text-align: right; font-weight: bold; color: red;">
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="DonHangChiTiet" role="tabpanel">
            <div class="box-body">
                <div class="row gx-4">
                    <div class="col-lg-11 col-sm-12 col-12">
                        <input [(ngModel)]="DonHangChiTietService.BaseParameter.SearchString" placeholder="Search ..."
                            class="form-control form-control-lg" (keyup.enter)="DonHangChiTietSearch()" disabled>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Search"
                            (click)="DonHangChiTietSearch()"><i class="fa fa-search"></i></a>
                    </div>
                </div>
                <div class="table-responsive">
                    <table mat-table matSort #DonHangChiTietSort="matSort"
                        [dataSource]="DonHangChiTietService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                No
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                <div *ngIf="DonHangChiTietPaginator">
                                    {{DonHangChiTietPaginator.pageSize *
                                    DonHangChiTietPaginator.pageIndex + i + 1}}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ID}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="DanhMucDichVuID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
                            <td mat-cell *matCellDef="let element" style="width: 300px;">
                                <select class="form-select" name="DanhMucDichVuID" *ngIf="element.ID==0"
                                    [(ngModel)]="element.DanhMucDichVuID" (change)="DanhMucDichVuChange(element)">
                                    <option *ngFor="let item of DanhMucDichVuService.List;" [value]="item.ID">
                                        {{item.Name}} [{{item.ID}}]</option>
                                </select>
                                <select class="form-select" name="DanhMucDichVuID" disabled *ngIf="element.ID>0"
                                    [(ngModel)]="element.DanhMucDichVuID" (change)="DanhMucDichVuChange(element)">
                                    <option *ngFor="let item of DanhMucDichVuService.List;" [value]="item.ID">
                                        {{item.Name}} [{{item.ID}}]</option>
                                </select>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="SoLuongTransation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Transation</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="number" placeholder="Transation"
                                    name="SoLuongTransation{{element.ID}}" [(ngModel)]="element.SoLuongTransation"
                                    style="text-align: right;" disabled>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="SoLuongGas">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gas</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="number" placeholder="Gas"
                                    name="SoLuongGas{{element.ID}}" [(ngModel)]="element.SoLuongGas" disabled
                                    style="text-align: right;">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="SoLuongThang">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Month</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="number" placeholder="Month"
                                    name="SoLuongThang{{element.ID}}" [(ngModel)]="element.SoLuongThang" disabled
                                    style="text-align: right;">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="SoLuong">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="number" placeholder="Quantity"
                                    name="SoLuong{{element.ID}}" [(ngModel)]="element.SoLuong"
                                    style="text-align: right;">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="DonGia">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="number" placeholder="Price"
                                    name="DonGia{{element.ID}}" [(ngModel)]="element.DonGia" style="text-align: right;"
                                    disabled>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="TongCong">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="number" placeholder="Total"
                                    name="TongCong{{element.ID}}" [(ngModel)]="element.TongCong" disabled
                                    style="text-align: right;">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a *ngIf="DonHangService.FormData.IsThanhToan!=true" class="btn btn-warning"
                                    (click)="DonHangChiTietSave(element)"><i class="fa fa-save"></i>
                                </a>&nbsp;&nbsp;&nbsp;
                                <a class="btn btn-danger" (click)="DonHangChiTietDelete(element)"
                                    *ngIf="element.ID>0 && DonHangService.FormData.IsThanhToan!=true"><i
                                        class="fa fa-trash"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="DonHangChiTietService.DisplayColumns001">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: DonHangChiTietService.DisplayColumns001;">
                        </tr>
                    </table>
                    <mat-paginator #DonHangChiTietPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                        [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="DonHangThanhToan" role="tabpanel">
            <div class="box-body">
                <div class="row gx-4">
                    <div class="col-lg-11 col-sm-12 col-12">
                        <input [(ngModel)]="DonHangThanhToanService.BaseParameter.SearchString" placeholder="Tìm ..."
                            class="form-control form-control-lg" (keyup.enter)="DonHangThanhToanSearch()" disabled>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Search"
                            (click)="DonHangThanhToanSearch()"><i class="fa fa-search"></i></a>
                    </div>
                </div>
                <div class="table-responsive">
                    <table mat-table matSort #DonHangThanhToanSort="matSort"
                        [dataSource]="DonHangThanhToanService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                No
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                <div *ngIf="DonHangThanhToanPaginator">
                                    {{DonHangThanhToanPaginator.pageSize *
                                    DonHangThanhToanPaginator.pageIndex + i + 1}}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ID}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="NgayGhiNhan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date created
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'" style="text-align: right;"
                                    (ngModelChange)="DonHangThanhToanDateNgayGhiNhan($event, element)"
                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                    autocomplete="off">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payer</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="text" placeholder="Payer" name="Name{{element.ID}}"
                                    [(ngModel)]="element.Name">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ThanhToan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="number" placeholder="Paid"
                                    name="ThanhToan{{element.ID}}" [(ngModel)]="element.ThanhToan"
                                    style="text-align: right; font-weight: bold; color: green;">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Note">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="text" placeholder="Note" name="Note{{element.ID}}"
                                    [(ngModel)]="element.Note">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a *ngIf="DonHangService.FormData.IsThanhToan!=true" class="btn btn-warning"
                                    (click)="DonHangThanhToanSave(element)"><i class="fa fa-save"></i>
                                </a>&nbsp;&nbsp;&nbsp;
                                <a class="btn btn-danger" (click)="DonHangThanhToanDelete(element)"
                                    *ngIf="element.ID>0 && DonHangService.FormData.IsThanhToan!=true"><i
                                        class="fa fa-trash"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="DonHangThanhToanService.DisplayColumns001">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: DonHangThanhToanService.DisplayColumns001;">
                        </tr>
                    </table>
                    <mat-paginator #DonHangThanhToanPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                        [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="DonHangTapTinDinhKem" role="tabpanel">
            <div class="box-body">
                <div class="row gx-4">
                    <div class="col-lg-11 col-sm-12 col-12">
                        <input [(ngModel)]="DonHangTapTinDinhKemService.BaseParameter.SearchString"
                            placeholder="Search ..." class="form-control form-control-lg"
                            (keyup.enter)="DonHangTapTinDinhKemSearch()" disabled>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Search"
                            (click)="DonHangTapTinDinhKemSearch()"><i class="fa fa-search"></i></a>
                    </div>
                </div>
                <div class="table-responsive">
                    <table mat-table matSort #DonHangTapTinDinhKemSort="matSort"
                        [dataSource]="DonHangTapTinDinhKemService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                No
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                <div *ngIf="DonHangTapTinDinhKemPaginator">
                                    {{DonHangTapTinDinhKemPaginator.pageSize *
                                    DonHangTapTinDinhKemPaginator.pageIndex + i + 1}}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ID}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <input class="form-control" type="text" placeholder="Name" name="Name{{element.ID}}"
                                    [(ngModel)]="element.Name" />
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="FileName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Upload
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <input type="file" style="width: 200px;"
                                    (change)="DonHangTapTinDinhKemChangeFileName($event.target.files)">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Note">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Download
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a *ngIf="element.ID > 0 && element.FileName" class="link-primary"
                                    href="{{element.FileName}}"><b>Tải về</b></a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a class="btn btn-warning" (click)="DonHangTapTinDinhKemSave(element)"><i
                                        class="fa fa-save"></i>
                                </a>&nbsp;&nbsp;&nbsp;
                                <a class="btn btn-danger" (click)="DonHangTapTinDinhKemDelete(element)"
                                    *ngIf="element.ID>0"><i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="DonHangTapTinDinhKemService.DisplayColumns001">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: DonHangTapTinDinhKemService.DisplayColumns001;">
                        </tr>
                    </table>
                    <mat-paginator #DonHangTapTinDinhKemPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                        [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="ThanhVienBlock" role="tabpanel">
            <div class="box-body">
                <div class="row gx-4">
                    <div class="col-lg-11 col-sm-12 col-12">
                        <input [(ngModel)]="ThanhVienBlockService.BaseParameter.SearchString" placeholder="Search ..."
                            class="form-control form-control-lg" (keyup.enter)="ThanhVienBlockSearch()" disabled>
                    </div>
                    <div class="col-lg-1 col-sm-12 col-12">
                        <a style="width: 100%;" class="btn btn-success" title="Search"
                            (click)="ThanhVienBlockSearch()"><i class="fa fa-search"></i></a>&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                <div class="table-responsive">
                    <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ThanhVienBlockSort="matSort"
                        [dataSource]="ThanhVienBlockService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                No
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                <div *ngIf="ThanhVienBlockPaginator">
                                    {{ThanhVienBlockPaginator.pageSize *
                                    ThanhVienBlockPaginator.pageIndex + i + 1}}
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ID}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="NgayGhiNhan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date created
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NgayGhiNhan |
                                date:'dd/MM/yyyy HH:mm:ss'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Code">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Token
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <a style="cursor: pointer;" title="{{element.Code}}" class="link-primary"
                                    (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="TypeName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.TypeName}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Function</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.Description}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="DiaChiChuyen">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sender</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.DiaChiChuyen}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="DiaChiNhan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Send To
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.DiaChiNhan}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ThanhVienBlockService.DisplayColumns001">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumns001;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="!NotificationService.IsMobile" #ThanhVienBlockPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                    <table *ngIf="NotificationService.IsMobile" mat-table matSort #ThanhVienBlockSort="matSort"
                        [dataSource]="ThanhVienBlockService.DataSource" class="table table-striped m-0">
                        <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                List
                            </th>
                            <td mat-cell *matCellDef="let element; index as i;">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <a class="form-label" style="cursor: pointer;" title="{{element.Name}}"
                                            class="link-primary"
                                            (click)="ThanhVienBlockAdd(element.ID)"><b>{{element.Code}}</b></a>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="ThanhVienBlockService.DisplayColumnsMobile">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ThanhVienBlockService.DisplayColumnsMobile;">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="NotificationService.IsMobile" #ThanhVienBlockPaginator="matPaginator"
                        [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DonHangService.IsShowLoading"></app-loading>